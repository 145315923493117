import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import LoadingComponent from './utils/LoadingComponent';

import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey(
  'basdhjk098uyghbncskmai0d98uyhbjnksaod98y7[v1]yh12300e57c62c6dcbf4e15d1f000c3d973c5'
);

const Root = lazy(() => import(/* webpackChunkName: "root" */ './root'));

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Suspense fallback={<LoadingComponent />}>
        <Component />
      </Suspense>
    </AppContainer>,
    document.getElementById('app-root')
  );
};

render(Root);

if (module.hot) {
  module.hot.accept('./root', () => {
    const newApp = require('./root').default;
    render(newApp);
  });
}
