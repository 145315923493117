import React from 'react';
import PropTypes from 'prop-types';
import spinner from 'images/new_spinner.svg';

class LoadingComponent extends React.Component {
  render() {
    const { height, loadingText } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: height || '100vh',
          width: '100%',
          WebkitTransition: 'opacity 3s ease-in-out',
        }}
      >
        <img src={spinner} alt="spinner" />
        {loadingText && <p>{loadingText}</p>}
      </div>
    );
  }
}

LoadingComponent.propTypes = {
  loadingText: PropTypes.string,
  height: PropTypes.string,
};

export default LoadingComponent;
